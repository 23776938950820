import { memo } from "react";
import dynamic from "next/dynamic";
import useTranslation from "next-translate/useTranslation";
import { useBusinessProfile } from "hooks/useFetch";
import BookNow from "components/BookNow/BookNow";
import BusinessFieldsType from "types/business.types";

const PhoneBadge = dynamic(() => import("components/Badges/Phone.badge"), {
	ssr: true,
});

const WorkingHours = dynamic(() => import("components/About/WorkingHours.about"), {
	ssr: true,
});

const Socials = dynamic(() => import("components/About/Socials.about"), {
	ssr: true,
});

type Props = { profile: BusinessFieldsType };

function Sticky({ profile }: Props) {
	const { data: businessProfile } = useBusinessProfile();
	const widgetSetting = businessProfile?.data.widget;
	const { t } = useTranslation("common");

	const isSocialHasItems = Object.keys(profile.publicLinks || {}).length > 0;

	return (
		<div className="col-span-12 lg:col-span-6 xl:col-span-5">
			<div className="sticky top-40 shadow-[rgba(0,_0,_0,_0.12)_0px_15px_30px_-12px] p-5 md:p-8 rounded-lg border border-zinc-200 bg-white">
				<i className="absolute top-8 bottom-8 left-1/2 -translate-x-1/2 w-[1px] bg-zinc-200 hidden md:block lg:hidden"></i>
				<div className="grid grid-cols-1 md:grid-cols-2 md:grid-rows-2 md:gap-x-16 lg:grid-cols-1 lg:grid-rows-none lg:gap-0">
					<div className="col-span-2 md:col-span-1 row-span-none md:row-span-1 lg:row-span-none mb-8 md:mb-0 lg:mb-8">
						<div className="flex align-center gap-3 flex-wrap flex-row md:flex-col lg:flex-row">
							<div className="flex-1">
								<BookNow hasLongTitle uniqueIdentifier={widgetSetting?.uniqueIdentifier} />
							</div>
							{profile.phoneNumber && (
								<div className="flex-1">
									<PhoneBadge phoneNumber={profile.phoneNumber} />
								</div>
							)}
						</div>
					</div>

					<div className="col-span-2 row-span-1 md:col-span-1 md:row-span-3">
						<h3 className="font-medium text-lg xl:text-xl mb-4">{t("workingHours.title")}</h3>
						<WorkingHours workingHours={profile.workingHours} />
					</div>

					{isSocialHasItems && (
						<div className="col-span-2 row-span-1 md:col-span-1 md:row-span-2 mt-8 md:mt-0 lg:mt-8 self-auto md:self-end lg:self-auto">
							<h3 className="font-medium text-lg xl:text-xl mb-4">{t("global.socials")}</h3>
							<Socials businessName={profile.name} publicLinks={profile.publicLinks} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default memo(Sticky);
