import React, { memo } from "react";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import classNames from "classnames";
import Link from "next/link";
import { generateBookingURL } from "utils/utils";
import { BookUrlType } from "types/book.type";

type Props = BookUrlType & {
	buttonWide?: boolean;
	hasLongTitle?: boolean;
};

function BookNow({ uniqueIdentifier, type, id, buttonWide, hasLongTitle }: Props) {
	const { locale } = useRouter();
	const { t } = useTranslation("common");
	if (!uniqueIdentifier) return null;

	const href = generateBookingURL({ uniqueIdentifier, type, id, locale });
	return (
		<Link
			href={href}
			className={classNames(
				"flex items-center justify-center border border-primary-500 text-sm rounded-lg text-primary-text bg-primary-500 uppercase font-medium hover:bg-primary-300 hover:border-primary-300 transition-colors cursor-pointer",
				{ "py-2 px-8": buttonWide },
				{ "py-3 px-5": !buttonWide }
				// { "rounded-full": buttonShape === "pill" }
			)}
		>
			{(hasLongTitle && t("global.booknow")) || t("global.book")}
		</Link>
	);
}

export default memo(BookNow);
